import SmoothScroll from "smooth-scroll";

$(function () {
  $.fn.autoKana('input[name="name"] ', 'input[name="name-kana"]', {
    katakana: true,
  }); //name属性で判別する場合
});

// aos.js
AOS.init({
  once: true,
  duration: 1000,
  easing: "ease-out-quad",
  anchorPlacement: "bottom-bottom",
});

document
  .querySelectorAll("img")
  .forEach((img) => img.addEventListener("load", () => AOS.refresh()));

// scroll-hint
new ScrollHint(".js-scrollable", {
  i18n: {
    scrollable: "スクロールします",
  },
});

var scroll = new SmoothScroll('a[href*="#"]', {
  speed: 500,
  speedAsDuration: true,
});

document.addEventListener("scroll", function () {
  let scrollPosition = window.pageYOffset;

  const cartEle = document.getElementById("cart");
  var featureOffset = $("#feature").offset();
  var productOffset = $("#product").offset();

  if (
    scrollPosition >= featureOffset.top &&
    scrollPosition <= productOffset.top
  ) {
    cartEle.classList.remove("face-out");
    cartEle.classList.add("face-in");
  } else {
    cartEle.classList.remove("face-in");
    cartEle.classList.add("face-out");
  }
});
